import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import useWindowDimensions from "lib/useWindowDimensions";
import YouTube from 'react-youtube';
export default function Company () {
    const {width, height} = useWindowDimensions();

    return (
        <div style={{width:'100%', padding:'0px 20px'}}>
            <div style={{width:'100%', padding:'50px 0px'}}>
                <span style={{fontSize:25, fontWeight:900}}>
                    싸인트럭 주식회사 소개
                </span>
                <div style={{paddingTop:20}}>
                    <span style={{whiteSpace:'pre-wrap', fontWeight:'bold', fontSize:16}}>
                        싸인트럭은 모든 화물, 모든 차종을 취급하는{'\n'}
                        종합물류회사 입니다.{'\n'}
                        모든 고객을 위해 책임을 다하겠습니다.
                    </span>
                </div>
            </div>

            <div>
             <div style={{height:10, width:20, borderTop:'3px solid black'}}/>
                <span style={{fontSize:20, fontWeight:900}}>주요 비즈니스 영역</span>
                <div style={{paddingTop:20, paddingBottom:50}}>
                <YouTube videoId="sqWvoQHDVlk" opts={{
                    height: width*0.5,
                    width: width*0.9,
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                    },
                }}
                    // onReady={this._onReady}
                />
                </div>
            </div>

            <div style={{
                paddingTop:20,
                paddingBottom:120,
                width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>

                <div style={{
                    // height:height*0.6,
                    display:'flex', flexDirection:'column',
                    alignItems:'space-between', justifyContent:'space-between',
                    width:'100%', backgroundColor:'#f5f5f5'}}>
                    <div>
                        <div style={{
                            whiteSpace:"pre-wrap",
                            margin:'0px 20px', padding:'20px 0px', borderBottom:'2px solid lightgray'}}>
                            <span style={{fontSize:25, fontWeight:'bold'}}>화물운송 사업본부</span><br/>
                            <span>모든 종류의 화물운송을{'\n'}책임지고 진행합니다.</span>
                        </div>
                        <div style={{padding:'20px 20px 0px 20px', lineHeight:1.5, whiteSpace:"pre-wrap",}}>
                            <span style={{margin:'5px 0px'}}>소형화물사업부: {'\n'}<span style={{fontWeight:'bold', fontSize:20}}>1~5톤 카고, 윙바디, 다마스</span></span><br/><br/>
                            <span style={{margin:'5px 0px'}}>대형화물사업부: {'\n'}<span style={{fontWeight:'bold', fontSize:20}}>5~25톤 카고, 윙바디, 츄레라</span></span><br/><br/>
                            {/*<span style={{margin:'5px 0px'}}>{'\n'}특수화물사업부</span><br/>*/}
                        </div>
                    </div>
                    <img src={require('img/turcks.png')}
                         style={{height:200, width:'100%'}}
                    />
                </div>

                <div style={{
                    marginTop:50,
                    // height:height*0.6,
                    display:'flex', flexDirection:'column',
                    alignItems:'space-between', justifyContent:'space-between',
                    width:'100%', backgroundColor:'#f5f5f5'}}>
                    <div>
                        <div style={{
                            whiteSpace:'pre-wrap',
                            margin:'0px 20px', padding:'20px 0px', borderBottom:'2px solid lightgray'}}>
                            <span style={{fontSize:25, fontWeight:'bold'}}>플랫폼 사업본부</span><br/>
                            <span>화물 플랫폼을 개발하고{'\n'}법률서비스를 제공합니다.</span>
                        </div>
                        <div style={{padding:'20px 20px 0px 20px', }}>
                            <span style={{margin:'5px 0px'}}>IT개발팀</span><br/><br/>
                            <span style={{margin:'5px 0px'}}>법무팀</span><br/><br/>
                        </div>
                    </div>

                    <img src={require('img/people.png')}
                    style={{height:200, width:'100%'}}
                         />
                </div>

            </div>



        </div>
    )
}