import './App.css';
import ChannelService from "./lib/channelService";
import React, {useEffect, Suspense} from "react";
import channelService from "./lib/channelService";
import useWindowDimensions from "./lib/useWindowDimensions";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Header from "./component/header";
import Home from "./component/home";
import Company from "./component/company";
import Footer from "./component/footer";

function App() {
  const { width, height } = useWindowDimensions();

  const location = useLocation();


  useEffect(()=>{
    console.log(width)
    console.log(location)
  },[width])

  const option =  {
    "pluginKey": "a4c09ee4-811a-4f20-83c2-98d138aec8f3",
    "customLauncherSelector": ".button",
    "hideChannelButtonOnBoot": true,
    // "memberId": 'yourMemberId',
    // "profile": {
    //   "name": "yourName",
    //   "mobileNumber": "010-xxxx-xxxx",
    //   "email": "yourEmail@gmail.com",
    // }
  }
  useEffect(()=>{
    channelService.boot(option);
    channelService.showChannelButton()
  },[])

  return (
      <div style={{position:'fixed', width:'100vw', height:'100vh'}}>
        <div style={{width:'100%', height:'100%', overflow:'auto',display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>
          <div style={{height:'100%', width:width, borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray'}}>
            <Header />
            <div style={{width:'100%', backgroundColor:'white',}}>
              <Routes>
                  <Route path={'/'} element={<Home />}/>
                  <Route path={'/company'} element={<Company />}/>
                  <Route path="/*" element={<div style={{width:'100%', height:'100%'}}>Not found</div>} />
              </Routes>
            </div>
              <div style={{width:'100%'}}>
                  <Footer />
              </div>
          </div>
        </div>
      </div>
  );
}

export default App;
