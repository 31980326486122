import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function Header () {
    const navigator = useNavigate();
    return (
        <div style={{
            height:60, alignItems:'center',
            width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <div
                className={'_click'}
                onClick={()=>{
                    navigator('')
                }}
                style={{width:'35%', paddingLeft:5}}>
                <img src={require('img/bi2.png')} height={30}/>
            </div>
            <div style={{display:'flex', flexDirection:'row', }}>

                <div
                    className={'_click'}
                    onClick={()=> document.location.href='tel:15511550'}
                    style={{backgroundColor:'#2e58ec', borderRadius:20, display:'flex',flexDirection:'row',
                    alignItems:'center', padding:'0px 20px 0px 5px', height:40, marginRight:5
                }}>
                    <img style={{marginRight:10}} src={require('img/phone2.png')} height={32}/>
                    <span style={{color:'white', fontSize:18, fontWeight:"bold"}}>전화</span>
                </div>

                <div
                className={'_click'}
                onClick={()=>{
                    navigator('company')
                }}
                    style={{backgroundColor:'#2e58ec', borderRadius:20, display:'flex',flexDirection:'row',
                    alignItems:'center', padding:'0px 15px', height:40, marginRight:5
                }}>
                    {/*<img style={{marginRight:10}} src={require('img/phone2.png')} height={20}/>*/}
                    <span style={{color:'white', fontSize:18, fontWeight:"bold"}}>회사소개</span>
                </div>
            </div>
        </div>
    )
}