import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import useWindowDimensions from "lib/useWindowDimensions";

export default function Footer () {
    const {width, height} = useWindowDimensions();
    return (
        <div style={{
            justifyContent:'center',
            width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            {/*<div style={{*/}
            {/*    width:width,*/}
            {/*    display:'flex', flexDirection:'row', justifyContent:'space-around'}}>*/}
            {/*    {*/}
            {/*        [require('img/asso1.png'),require('img/asso2.png'),require('img/asso3.png')].map((img)=>{*/}
            {/*            return(*/}
            {/*                <img src={img} height={60} />*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
                <div style={{width:'100%', paddingBottom:70, backgroundColor:'#f5f5f5',
                    display:'flex', flexDirection:'row', justifyContent:'center'
                }}>
                    <div style={{width:width*0.8,}}>
                    <div style={{paddingTop:50, paddingBottom:10}}>
                        <span style={{fontSize:18, fontWeight:'bold'}}>싸인트럭 주식회사</span>
                    </div>
                        <div style={{paddingTop:20, paddingBottom:10}}>
                            <span style={{fontSize:18, fontWeight:'bold'}}>적재물 책임보험: 롯데손해보험(주)</span>
                        </div>
                    {
                        ['사업자등록번호: 329-86-02903',
                            '화물운송주선사업자: 제 369 호',
                            // '대표: 금현경',
                            // '주소: 경기도 광주시 경안로 23, 4층 175호',
                            '연락처: 1551-1550','이메일: call365call@gmail.com']
                            .map((txt)=>{
                                return(
                                    <div style={{}}>
                                        <span style={{fontSize:12}}>{txt}</span>
                                    </div>
                                )
                            })
                    }
                        <div style={{paddingTop:20, paddingBottom:10}}>
                            <span style={{fontSize:18, fontWeight:'bold'}}>고객센터: 1551-1550</span>
                        </div>
                </div>
            </div>
        </div>
    )
}