import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import useWindowDimensions from "lib/useWindowDimensions";
import YouTube from 'react-youtube';
import {API_SERVER} from "devConfig";


export default function Home () {
    const termsRef = useRef(null)
    const {width, height} = useWindowDimensions();

    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyModalStep, setHistoryModalStep] = useState(1);

    const [historyList, setHistoryList] = useState([]);
    const [historyOffset, setHistoryOffset] = useState(4);
    const [newConsult, setNewConsult] = useState({});

    const [selectedConsult, setSelectedConsult] = useState(null);
    const selectedPwdRef = useRef(null);

    const [modalTerms, setModalTerms] = useState(false);


useEffect(()=>{
    fetch(API_SERVER + '/getConsultList')
        .then(res => res.json())
        .then(async json => {
            if(json['suc']){
                setHistoryList([...json['result']])
            }
        });
},[])

    useEffect(()=>{
        if(!historyModalOpen){
            setHistoryOffset(4);
            setHistoryModalStep(1);
        }
    },[historyModalOpen])

    const renderHistoryModal=()=>{
        var result;
        switch (historyModalStep){
            case 3:
                if(selectedConsult)
                result =
                    <div>
                    <div style={{width:'100%'}}>
                        {
                            [
                                {key : 'name', showKey : '상호명/이름'},
                                {key : 'phone', showKey : '연락처'},
                                {key : 'ques', showKey : '문의사항'},
                                {key : 'ans', showKey : '답변'},
                            ].map((item,i)=>{
                                return(
                                    <div style={{
                                        display:'flex', flexDirection:'row', margin:'15px 10px'}}>
                                        <div style={{width:'30%', display:'flex', flexDirection:"column", justifyContent:'center'}}>
                                            <span style={{fontSize:16}}>
                                                {item['showKey']}
                                            </span>
                                        </div>
                                        <div style={{width:'60%'}}>
                                            {
                                                i > 1 ?
                                                    <Form.Control

                                                        rows={3}
                                                        as={i > 1 ? 'textarea' : ''}
                                                        value={selectedConsult[item['key']] || null}
                                                        disabled={true}
                                                    />:
                                                    <Form.Control
                                                        value={selectedConsult[item['key']] || null}
                                                        disabled={true}
                                                    />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                <div style={{width:'100%', textAlign:'center', marginBottom:30, marginTop:20}}>
                    <Button
                        onClick={()=>{
                            setHistoryModalOpen(false)
                        }}
                        style={{width:160, height:50, fontSize:20, fontWeight:'bold'}}>
                        확인
                    </Button>
                </div>
                    </div>
                break;
            case 2:
                if(selectedConsult)
                result =
                    <div style={{
                        width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap',
                        justifyContent:'center'
                    }}>
                        <div style={{
                            // height:height*0.2,
                            width:'70%',
                            padding:'50px 0px',
                            display:'flex', flexDirection:'row', alignItems:'center',
                            flexWrap:'wrap',  textAlign:'center'
                            }}>
                            <div style={{width:'100%'}}>
                                <span style={{fontWeight:'bold'}}>
                                    비밀번호를 입력해주세요
                                </span>
                            </div>

                            <Form.Control
                                ref={selectedPwdRef}
                                style={{textAlign:'center', marginTop:10}}
                                width={'80%'}
                                placeholder={'비밀번호'}
                                value={null}/>
                        </div>

                        <div style={{width:'100%', margin:'30px 0px', textAlign:'center'}}>
                            <Button
                                onClick={()=>{
                                    if(selectedConsult && selectedPwdRef
                                     && selectedPwdRef.current.value.trim() == selectedConsult['pwd']){
                                        setHistoryModalStep(3)
                                    }else{
                                        window.alert('비밀번호를 확인해주세요');
                                    }
                                    console.log(selectedConsult)
                                }}
                                style={{width:160, height:50, fontSize:20, fontWeight:'bold'}}>
                                확인
                            </Button>
                        </div>

                    </div>
                break;
            case 1:
                result =
                    <div style={{
                        width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>
                        {
                            historyList.slice(0, historyOffset)
                                .map((item,i )=>{
                                    return(
                                        <div
                                            className={'_click'}
                                            onClick={async ()=>{
                                                console.log(item)
                                                await setSelectedConsult(item);
                                                setHistoryModalStep(2);
                                            }}
                                        id={'history_id_'+i}
                                            style={{
                                            // borderTop: i == 0 ? '1px solid lightgray' : 0,
                                            padding:'15px 0px', borderBottom:'1px solid lightgray',
                                            width:'80%',
                                            display:'flex', flexDirection:'row',
                                            justifyContent:'space-between',
                                            alignItems:'center'
                                        }}>
                                            <div style={{ width:'40%'}}>
                                                <span>견적문의</span><br/>
                                                <span style={{color:'gray'}}>{maskName(item['name'])}</span>
                                            </div>
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                <div style={{
                                                    border:'2px solid #2e58ec',
                                                    backgroundColor:item['ans'] ? '#2e58ec' : 'white',
                                                    color:item['ans'] ? 'white' : '#2e58ec',
                                                    height:30,
                                                    display:'flex', flexDirection:'row', alignItems:'center',
                                                    marginRight:10,
                                                    padding:'0px 15px', borderRadius:7
                                                }}>
                                                    <span>
                                                        {item['ans'] ? '답변완료' : '답변대기'}
                                                    </span>
                                                </div>
                                                <div

                                                >
                                                    <img src={require('img/arrow.png')} height={30} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                        <div style={{width:'100%', textAlign:'center', padding:'30px 0px'}}>
                            <Button
                                onClick={()=>{
                                    console.log(historyOffset)
                                    setHistoryOffset((prev)=>prev + 4);

                                    // document.getElementById('id').scrollIntoView();
                                }}
                                style={{width:160, height:50, fontSize:20, fontWeight:'bold'}}>
                                더 보기 +
                            </Button>
                        </div>
                    </div>
                break;
            default:
                break;
        }
        return result;
    }
    const maskName=(name)=>{


        var _name = ''
        var maskNum = parseInt(name.length / 3)
        var startIdx = maskNum;
        var endIdx = name.length - startIdx
        if(name.length < 4){
            _name = name.split('').map((ch, i)=>[1].indexOf(i) > -1 ? '*' : ch)
        }else {
            // startIdx = name.length % 2== 0 ? startIdx : startIdx + 1
            _name = name.split('').map((ch, i)=> (i >= startIdx && i < endIdx) ? '*' : ch)
        }
        // else if(maskNum < 2){
        //     _name = name.split('').map((ch, i)=> i >= startIdx && i < startIdx + maskNum ? '*' : ch)
        // }else if(maskNum < 3){
        //     _name = name.split('').map((ch, i)=> i >= startIdx && i < startIdx + maskNum + 1 ? '*' : ch)
        // }else{
        //     _name = name.split('').map((ch, i)=> i >= startIdx && i < startIdx + maskNum + 1 ? '*' : ch)
        // }

        // var _name  = name.split('').map((ch, i)=>[2,3,4].indexOf(i) > -1 ? '*' : ch);
        return _name.join('')
    }
    return (
        <div>
            <div style={{
                marginTop:height*0.1, textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',}}>

                {/*<div style={{fontWeight:'bold', whiteSpace:'pre-wrap', width:'100%', alignItems:'center' }}>*/}
                <div>
                    <span style={{fontSize:45, lineHeight:1.2}}>
                        모든 화물운송<br/>
                        믿고 맡겨주세요
                    </span>
                    <br/><br/>
                    <span style={{fontSize:22, marginTop:height*0.02}}>
                        기업물류, 개인용달{'\n'}
                        모든 차종 가능!
                    </span>
                </div>

                    <div style={{width:'fit-content', marginTop:height*0.07}}>

                            <div
                                className={'_click'}
                                onClick={()=> document.location.href='tel:15511550'}
                                style={{
                                display:'flex', flexDirection:"column",
                                alignItems:'center', justifyContent:'center',
                                fontSize:20, fontWeight:'bold',
                                padding:'0px 40px',
                                height:60, borderRadius:30, backgroundColor:'#2e58ec',
                                border:'3px solid black', color:'white'
                            }}>
                                <span>1551-1550 전화걸기 ></span>
                            </div>

                        {/*<div*/}
                        {/*onClick={()=>{*/}
                        {/*    var element = document.getElementById('_consult');*/}
                        {/*    if(element){*/}
                        {/*        element.scrollIntoView({*/}
                        {/*            behavior: "smooth",*/}
                        {/*            // block: "end",*/}
                        {/*            // inline: "nearest"*/}
                        {/*        });*/}
                        {/*    }*/}

                        {/*}}*/}
                        {/*    style={{*/}
                        {/*    marginTop:10,*/}
                        {/*    display:'flex', flexDirection:"column",*/}
                        {/*    alignItems:'center', justifyContent:'center',*/}
                        {/*    fontSize:20, fontWeight:'bold',*/}
                        {/*    padding:'0px 40px',*/}
                        {/*    height:60, borderRadius:30,*/}
                        {/*    // backgroundColor:'blue', color:'white'*/}
                        {/*    border:'3px solid black',*/}
                        {/*}}>*/}
                        {/*    <span>견적문의 게시판 {" →"}</span>*/}
                        {/*</div>*/}
                        <div
                            className={'_click'}
                            onClick={()=> document.location.href='http://pf.kakao.com/_ApMIG/chat'}
                            style={{
                            marginTop:10,
                            display:'flex', flexDirection:"column",
                            alignItems:'center', justifyContent:'center',
                            fontSize:20, fontWeight:'bold',
                            padding:'0px 40px',
                            height:60, borderRadius:30, backgroundColor:'#f7e111',
                            border:'3px solid black'
                        }}>
                            <span>카카오톡 상담 ></span>
                        </div>
                    </div>
                </div>
                {/*<div style={{}}>*/}
                {/*    <img src={require('img/truck.png')} height={height*0.5}/>*/}
                {/*</div>*/}
            {/*</div>*/}


            <div style={{
                marginTop:height*0.1, textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',}}>
                <img src={require('img/truck.png')} height={height*0.4}/>
            </div>


            {/*    <div*/}
            {/*        id={'_consult'}*/}
            {/*        style={{*/}
            {/*            marginTop:height*0.1, textAlign:'center',*/}
            {/*            justifyContent:'center', alignItems:'center', flexWrap:'wrap',*/}
            {/*            width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',}}>*/}

            {/*        <div style={{width:'100%', textAlign:'center'}}>*/}
            {/*            <span style={{fontSize:25}}>*/}
            {/*                견적문의*/}
            {/*            </span><br/>*/}
            {/*            <span style={{color:'#878287', fontSize:20}}>*/}
            {/*                빠르게 답변드립니다*/}
            {/*            </span>*/}
            {/*        </div>*/}
            {/*        <div style={{width:'100%'}}>*/}
            {/*            {*/}
            {/*                [*/}
            {/*                    {key : 'name', showKey : '상호명/이름'},*/}
            {/*                    {key : 'phone', showKey : '연락처', require:1},*/}
            {/*                    {key : 'pwd', showKey : '비밀번호', require:1},*/}
            {/*                    {key : 'ques', showKey : '문의사항'},*/}
            {/*                ].map((item, i)=>{*/}
            {/*                    return(*/}
            {/*                        <div style={{*/}
            {/*                            // borderBottom:'1px solid lightgray',*/}
            {/*                            margin:'0px 20px',*/}
            {/*                            display:'flex', flexDirection:'row', padding:'20px 0px'}}>*/}
            {/*                            <div style={{width:'30%', display:'flex', flexDirection:"column", justifyContent:'center'}}>*/}
            {/*                                <span style={{fontSize:18}}>*/}
            {/*                                    {item['showKey']}*/}
            {/*                                {*/}
            {/*                                    Object.keys(item).includes('require') &&*/}
            {/*                                    <span style={{color:'red', marginLeft:5}}>*</span>*/}
            {/*                                }*/}
            {/*                                </span>*/}
            {/*                            </div>*/}
            {/*                            <div style={{width:'60%'}}>*/}
            {/*                                {*/}
            {/*                                    item['key'] == 'ques' ?*/}
            {/*                                        <Form.Control*/}
            {/*                                            as={'textarea'}*/}
            {/*                                            type={item['key'] == 'pwd' ? 'password' : 'text'}*/}
            {/*                                            value={newConsult[item['key']] || ''}*/}
            {/*                                            onChange={(e)=>{*/}
            {/*                                                setNewConsult((prev)=> {*/}
            {/*                                                    return({*/}
            {/*                                                        ...prev,*/}
            {/*                                                        [item['key']] : e.target.value*/}
            {/*                                                    })*/}
            {/*                                                })*/}
            {/*                                            }*/}
            {/*                                            }*/}
            {/*                                        />:*/}
            {/*                                        <Form.Control*/}
            {/*                                            type={item['key'] == 'pwd' ? 'password' : 'text'}*/}
            {/*                                            value={newConsult[item['key']] || ''}*/}
            {/*                                            onChange={(e)=>{*/}
            {/*                                                setNewConsult((prev)=> {*/}
            {/*                                                    return({*/}
            {/*                                                        ...prev,*/}
            {/*                                                        [item['key']] : e.target.value*/}
            {/*                                                    })*/}
            {/*                                                })*/}
            {/*                                            }*/}
            {/*                                            }*/}
            {/*                                        />*/}
            {/*                                }*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*            <div style={{width:'100%', display:'flex', flexDirection:'row',*/}
            {/*                justifyContent:'space-between',*/}
            {/*                padding:'20px 20px'*/}
            {/*            }}>*/}
            {/*                <div*/}
            {/*                className={'_click'}*/}
            {/*                onClick={()=>{*/}
            {/*                    termsRef.current.checked = !termsRef.current.checked;*/}
            {/*                }}*/}
            {/*                    style={{*/}
            {/*                        fontSize:18,*/}
            {/*                        // padding:'10px 0px',*/}
            {/*                        display:'flex', flexDirection:'row', justifyContent:'center'}}>*/}
            {/*                    <span>서비스 이용약관 동의</span>*/}
            {/*                    <Form.Check*/}
            {/*                        // disabled={true}*/}
            {/*                        ref={termsRef}*/}
            {/*                        style={{marginLeft:10, }}*/}
            {/*                        type={'checkbox'}*/}
            {/*                        onClick={(e)=> {*/}
            {/*                            termsRef.current.checked = !termsRef.current.checked*/}
            {/*                        }}*/}
            {/*                        // value*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div*/}
            {/*                    className={'_click'}*/}
            {/*                    onClick={()=>{*/}
            {/*                        setModalTerms(true)*/}
            {/*                    }}*/}
            {/*                    style={{display:'flex', flexDirection:'row', justifyContent:'center'*/}
            {/*                        ,borderBottom:'1px solid lightgray', marginRight:15*/}
            {/*                    }}>*/}
            {/*                    <span style={{fontWeight:'normal', fontSize:14, color:'gray'}}>상세보기</span>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div style={{width:'100%', textAlign:'center'}}>*/}
            {/*            <Button*/}
            {/*            onClick={()=>{*/}
            {/*                if(newConsult['phone'] && newConsult['phone'] != ''*/}
            {/*                    && newConsult['pwd'] && newConsult['pwd'] != ''){*/}

            {/*                    if(!termsRef.current.checked){*/}
            {/*                        window.alert('이용약관을 동의해주세요');*/}
            {/*                        return ;*/}
            {/*                    }*/}

            {/*                    fetch(API_SERVER + '/upsertConsult' ,{*/}
            {/*                        method: 'POST',*/}
            {/*                        headers: {*/}
            {/*                            Accept: 'application/json',*/}
            {/*                            'Content-Type': 'application/json'*/}
            {/*                        },*/}
            {/*                        body: JSON.stringify({*/}
            {/*                            consult:newConsult*/}
            {/*                        })*/}
            {/*                    })*/}
            {/*                        .then(res => res.json())*/}
            {/*                        .then(json => {*/}
            {/*                            console.log(json)*/}
            {/*                            if(json['suc']){*/}
            {/*                                var _historyList = historyList;*/}
            {/*                                _historyList.unshift(newConsult);*/}
            {/*                                setHistoryList([..._historyList]);*/}
            {/*                                setNewConsult({});*/}
            {/*                                termsRef.current.checked = false;*/}

            {/*                                window.alert('문의가 등록되었습니다. 빠른 답변 드리겠습니다')*/}
            {/*                            }else{*/}
            {/*                                window.alert('서버와의 통신이 원활하지 않습니다. 잠시후 다시 시도해 주세요')*/}
            {/*                            }*/}
            {/*                        })*/}
            {/*                }else{*/}
            {/*                    window.alert('연락처와 비밀번호는 필수사항입니다');*/}
            {/*                }*/}

            {/*            }}*/}
            {/*                style={{width:160, height:50, fontSize:20, fontWeight:'bold'}}>*/}
            {/*                등록하기*/}
            {/*            </Button>*/}
            {/*            <Button*/}
            {/*                onClick={()=>{*/}
            {/*                    // var element = document.getElementById('_history');*/}
            {/*                    // console.log(element)*/}
            {/*                    // if(element){*/}
            {/*                    //     element.scrollIntoView({*/}
            {/*                    //         behavior: "smooth",*/}
            {/*                    //         // block: "end",*/}
            {/*                    //         // inline: "nearest"*/}
            {/*                    //     });*/}
            {/*                    // }*/}
            {/*                    setHistoryModalOpen(true)*/}
            {/*                }}*/}
            {/*                style={{*/}
            {/*                    border:'2px solid #2e58ec',*/}
            {/*                    color:'#2e58ec',*/}
            {/*                    backgroundColor:'white',*/}
            {/*                    marginLeft:10,*/}
            {/*                    width:160, height:50, fontSize:20, fontWeight:'bold'}}>*/}
            {/*                답변확인*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*<div*/}
            {/*    id={'_history'}*/}
            {/*    style={{*/}
            {/*        marginTop:height*0.1, textAlign:'center',*/}
            {/*        justifyContent:'center', alignItems:'center', flexWrap:'wrap',*/}
            {/*        width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',}}>*/}

            {/*        <div style={{width:'100%', textAlign:'center'}}>*/}
            {/*            <span style={{fontSize:25}}>*/}
            {/*                견적 리스트*/}
            {/*            </span>*/}
            {/*        </div>*/}
            {/*        <div style={{*/}
            {/*            margin:'35px 0px',*/}
            {/*            width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>*/}
            {/*            {*/}
            {/*                historyList.slice(0,4)*/}
            {/*                    .map((item,i )=>{*/}
            {/*                    return(*/}
            {/*                        <div*/}
            {/*                            className={'_click'}*/}
            {/*                            onClick={async ()=>{*/}
            {/*                                console.log(item)*/}
            {/*                                await setSelectedConsult(item);*/}
            {/*                                setHistoryModalStep(2);*/}
            {/*                                setHistoryModalOpen(true);*/}
            {/*                            }}*/}
            {/*                            style={{*/}
            {/*                            borderTop: i == 0 ? '1px solid lightgray' : 0,*/}
            {/*                            padding:'15px 0px', borderBottom:'1px solid lightgray',*/}
            {/*                            width:'80%',*/}
            {/*                            display:'flex', flexDirection:'row',*/}
            {/*                            justifyContent:'space-between',*/}
            {/*                            alignItems:'center'*/}
            {/*                        }}>*/}
            {/*                                <div style={{ width:'40%'}}>*/}
            {/*                                    <span>견적문의</span><br/>*/}
            {/*                                    <span style={{color:'gray'}}>{maskName(item['name'])}</span>*/}
            {/*                                </div>*/}
            {/*                                <div style={{display:'flex', flexDirection:'row'}}>*/}
            {/*                                    <div style={{*/}
            {/*                                        border:'2px solid #2e58ec',*/}
            {/*                                        backgroundColor:item['ans'] ? '#2e58ec' : 'white',*/}
            {/*                                        color:item['ans'] ? 'white' : '#2e58ec',*/}
            {/*                                        height:30,*/}
            {/*                                        display:'flex', flexDirection:'row', alignItems:'center',*/}
            {/*                                        marginRight:10,*/}
            {/*                                        padding:'0px 15px', borderRadius:7*/}
            {/*                                    }}>*/}
            {/*                                        <span>*/}
            {/*                                            {item['ans'] ? '답변완료' : '답변대기'}*/}
            {/*                                        </span>*/}
            {/*                                    </div>*/}
            {/*                                    <div*/}
            {/*                                    >*/}
            {/*                                        <img src={require('img/arrow.png')} height={30} />*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div style={{width:'100%', textAlign:'center', }}>*/}
            {/*            <Button*/}
            {/*            onClick={()=>{*/}
            {/*                setHistoryModalOpen(true)*/}
            {/*            }}*/}
            {/*                style={{width:160, height:50, fontSize:20, fontWeight:'bold'}}>*/}
            {/*                더 보기 +*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}


            <div style={{
                marginTop:height*0.15,
                marginBottom:height*0.15,
                justifyContent:'center', alignItems:'center',
                width:'100%', display:'flex', flexDirection:'row', fontWeight:'bold'}}>
                <YouTube videoId="sqWvoQHDVlk" opts={{
                    height: width*0.5,
                    width: width*0.9,
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                    },
                }}
                         // onReady={this._onReady}
                />
            </div>




            <Modal show={historyModalOpen} onHide={()=> {
                setHistoryModalOpen(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:'100%', border:0}}>
                        <div style={{textAlign:'center', width:'100%'}}>
                            <span>견적 리스트</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding:0}}>
                    <div style={{
                    }}>
                        {
                            renderHistoryModal()
                        }
                    </div>
                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*    <Button variant="secondary" onClick={handleClose}>*/}
                {/*        Close*/}
                {/*    </Button>*/}
                {/*    <Button variant="primary" onClick={handleClose}>*/}
                {/*        Save Changes*/}
                {/*    </Button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
            <Modal show={modalTerms} onHide={()=> {
                setModalTerms(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:'100%', border:0}}>
                        <div style={{textAlign:'center', width:'100%'}}>
                            <span>이용 약관</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    padding:0,
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div style={{width:'100%', padding:20}}>
                        <iframe
                            width={'100%'}
                            height={height*0.8}
                            src={API_SERVER + '/terms'}></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}